@font-face {
  font-family: 'SNsana';
  src: url('../Font/SNsana.ttf') format('truetype'), url('../Font/SNsana.otf') format('opentype');
}

@font-face {
  font-family: 'Iansui';
  src: url('../Font/Iansui.ttf') format('truetype'), url('../Font/Iansui.otf') format('opentype');
}

.SNsana {
  font-family: 'SNsana';
}

.Iansui {
  font-family: 'Iansui';
}

.hide {
  display: none !important;
  user-select: none;
}

.background {
  background: url('../Image/Backgroung.png') no-repeat fixed center;
  width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#StartPage {
  width:100vw;
  height:100vh;
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25vh;
    font-size: 8vmin;
    animation: titleShow 2s 1;
  }
  .lang {
    position: relative;
    padding: 10px;
    font-size: 2vmin;
    div:hover {
      cursor: pointer;
      color: #618255;
      margin-left:10px
    }
  }
  .diff {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
    font-size: 6vmin;
    animation: diffShow 4s 1;
    div:hover {
      cursor: pointer;
      color: #618255;
      margin-top:-10px
    }
  }
}

#IntroPage {
  width:100vw;
  height:100vh;
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30vh;
    font-size: 6vmin;
    animation: diffShow 1s 1;
  }
  .intro {
    width: 50vw;
    margin: 30px auto 30px auto;
    font-size: 2vmin;
    animation: diffShow 2s 1;
  }
  .game {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6vmin;
    margin-top: 5vh;
    padding: 10px;
    background: #bfad7756;
    animation: diffShow 4s 1;
    &:hover{
      cursor: pointer;
      color: #618255;
    }
  }
}

#GamePage {
  width:100vw;
  height:100vh;
  .cardCase {
    position: relative;
    width: 76vmin;
    height: 76vmin;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    margin: 0px auto;
    margin-top: 20vh;
    &.easy{
      width: 54vmin;
      height: 54vmin;
    }
    &.norm{
      width: 76vmin;
      height: 51vmin;
    }
    &.hard{
      width: 76vmin;
      height: 76vmin;
    }
  }
  .cardDesc {
    position: relative;
    margin: 1vh auto;
    width:80vw;
    font-size: 2vmin;
    &.descShow{
      animation: descShow 1s 1;
      animation-fill-mode: forwards;
    }
  }
}

#Card{
  position: relative;
  width:11vmin;
  height:11vmin;
  opacity: 0;
  &.cardIn{
    animation: cardIn 2s 1;
    animation-fill-mode: forwards;
  }
  div {
    width: 100%;
    height: 100%;
  }
  .back {
    background: url('../Image/CardBack.png') no-repeat center;
    background-size: 100%;
    position: absolute;
    transform: rotateY(0deg);
    transition: all 0.5s ;
    &.flip{
      transform: rotateY(90deg);
    }
    &.flipBack {
      transform: rotateY(0deg);
      transition-delay: 1s;
    }
  }

  .front {
    position: absolute;
    transition: all 0.5s ;
    transition-delay: 0.5s;
    transform: rotateY(90deg);
    &.flip{
      transform: rotateY(0deg);
    }
    &.filpBack {
      transform: rotateY(90deg);
    }
    &._1{
      background: url('../Image/Card1.png') no-repeat center;
      background-size: 100%;
    }
    &._2{
      background: url('../Image/Card2.png') no-repeat center;
      background-size: 100%;
    }
    &._3{
      background: url('../Image/Card3.png') no-repeat center;
      background-size: 100%;
    }
    &._4{
      background: url('../Image/Card4.png') no-repeat center;
      background-size: 100%;
    }
    &._5{
      background: url('../Image/Card5.png') no-repeat center;
      background-size: 100%;
    }
    &._6{
      background: url('../Image/Card6.png') no-repeat center;
      background-size: 100%;
    }
    &._7{
      background: url('../Image/Card7.png') no-repeat center;
      background-size: 100%;
    }
    &._8{
      background: url('../Image/Card8.png') no-repeat center;
      background-size: 100%;
    }
    &._9{
      background: url('../Image/Card9.png') no-repeat center;
      background-size: 100%;
    }
    &._10{
      background: url('../Image/Card10.png') no-repeat center;
      background-size: 100%;
    }
    &._11{
      background: url('../Image/Card11.png') no-repeat center;
      background-size: 100%;
    }
    &._12{
      background: url('../Image/Card12.png') no-repeat center;
      background-size: 100%;
    }
    &._13{
      background: url('../Image/Card13.png') no-repeat center;
      background-size: 100%;
    }
    &._14{
      background: url('../Image/Card14.png') no-repeat center;
      background-size: 100%;
    }
    &._15{
      background: url('../Image/Card15.png') no-repeat center;
      background-size: 100%;
    }
    &._16{
      background: url('../Image/Card16.png') no-repeat center;
      background-size: 100%;
    }
    &._17{
      background: url('../Image/Card17.png') no-repeat center;
      background-size: 100%;
    }
    &._18{
      background: url('../Image/Card18.png') no-repeat center;
      background-size: 100%;
    }
  }
}

#EndPage {
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25vh;
    font-size: 8vmin;
    animation: titleShow 2s 1;
  }
  .start {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
    font-size: 50px;
    animation: diffShow 4s 1;
    &:hover {
      cursor: pointer;
      color: #618255;
      margin-top:90px
    }
  }
}

@media only screen and (max-width:800px) {
  #StartPage {
    width:100vw;
    height:100vh;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25vh;
      font-size: 12vmin;
      animation: titleShow 2s 1;
      &.en {
        font-size: 10vmin;
      }
    }
    .lang {
      position: relative;
      padding: 10px;
      font-size: 5vmin;
      div:hover {
        cursor: pointer;
        color: #618255;
        margin-left:10px
      }
    }
    .diff {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      margin-top: 100px;
      font-size: 10vmin;
      animation: diffShow 4s 1;
      div:hover {
        cursor: pointer;
        color: #618255;
        margin-top:-10px
      }
    }
  }
  
  #IntroPage {
    width:100vw;
    height:100vh;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30vh;
      font-size: 12vmin;
      animation: diffShow 1s 1;
      &.en {
        font-size: 8vmin;
      }
    }
    .intro {
      width: 75vw;
    margin: 30px auto 30px auto;
    font-size: 4vmin;
    animation: diffShow 2s 1;
    }
    .game {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 6vmin;
      margin-top: 5vh;
      padding: 10px;
      background: #bfad7756;
      animation: diffShow 4s 1;
      &:hover{
        cursor: pointer;
        color: #618255;
      }
    }
  }
  
  #GamePage {
    width:100vw;
    height:100vh;
    .cardCase {
      position: relative;
      width: 76vmin;
      height: 76vmin;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
      margin: 0px auto;
      margin-top: 20vh;
      &.easy{
        width: 86vmin;
        height: 66vmin;
        margin-top: 20vh;
      }
      &.norm{
        width: 95vmin;
        height: 135vmin;
        margin-top: 3vh;
      }
      &.hard{
        width: 97vmin;
        height: 97vmin;
        margin-top: 15vh;
      }
    }
    .cardDesc {
      position: relative;
      margin: 1vh auto;
      width:80vw;
      &.en {
        &.norm{
          font-size: 3vmin;
        }
        &.hard{
          font-size: 4vmin;
        }
      }
      &.descShow{
        animation: descShow 1s 1;
        animation-fill-mode: forwards;
      }
    }
  }
  
  #Card{
    position: relative;
    width:20vmin;
    height:20vmin;
    opacity: 0;
    &.cardIn{
      animation: cardIn 2s 1;
      animation-fill-mode: forwards;
    }
    &.hard{
      width:15vmin;
      height:15vmin;
    }
    div {
      width: 100%;
      height: 100%;
    }
    .back {
      background: url('../Image/CardBack.png') no-repeat center;
      background-size: 100%;
      position: absolute;
      transform: rotateY(0deg);
      transition: all 0.5s ;
      &.flip{
        transform: rotateY(90deg);
      }
      &.flipBack {
        transform: rotateY(0deg);
        transition-delay: 1s;
      }
    }
  
    .front {
      position: absolute;
      transition: all 0.5s ;
      transition-delay: 0.5s;
      transform: rotateY(90deg);
      &.flip{
        transform: rotateY(0deg);
      }
      &.filpBack {
        transform: rotateY(90deg);
      }
      &._1{
        background: url('../Image/Card1.png') no-repeat center;
        background-size: 100%;
      }
      &._2{
        background: url('../Image/Card2.png') no-repeat center;
        background-size: 100%;
      }
      &._3{
        background: url('../Image/Card3.png') no-repeat center;
        background-size: 100%;
      }
      &._4{
        background: url('../Image/Card4.png') no-repeat center;
        background-size: 100%;
      }
      &._5{
        background: url('../Image/Card5.png') no-repeat center;
        background-size: 100%;
      }
      &._6{
        background: url('../Image/Card6.png') no-repeat center;
        background-size: 100%;
      }
      &._7{
        background: url('../Image/Card7.png') no-repeat center;
        background-size: 100%;
      }
      &._8{
        background: url('../Image/Card8.png') no-repeat center;
        background-size: 100%;
      }
      &._9{
        background: url('../Image/Card9.png') no-repeat center;
        background-size: 100%;
      }
      &._10{
        background: url('../Image/Card10.png') no-repeat center;
        background-size: 100%;
      }
      &._11{
        background: url('../Image/Card11.png') no-repeat center;
        background-size: 100%;
      }
      &._12{
        background: url('../Image/Card12.png') no-repeat center;
        background-size: 100%;
      }
      &._13{
        background: url('../Image/Card13.png') no-repeat center;
        background-size: 100%;
      }
      &._14{
        background: url('../Image/Card14.png') no-repeat center;
        background-size: 100%;
      }
      &._15{
        background: url('../Image/Card15.png') no-repeat center;
        background-size: 100%;
      }
      &._16{
        background: url('../Image/Card16.png') no-repeat center;
        background-size: 100%;
      }
      &._17{
        background: url('../Image/Card17.png') no-repeat center;
        background-size: 100%;
      }
      &._18{
        background: url('../Image/Card18.png') no-repeat center;
        background-size: 100%;
      }
    }
  }
  
  #EndPage {
    .title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25vh;
      font-size: 12vmin;
      animation: titleShow 2s 1;
    }
    .start {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      margin-top: 100px;
      font-size: 50px;
      animation: diffShow 4s 1;
      &:hover {
        cursor: pointer;
        color: #618255;
        margin-top:90px
      }
    }
  }
}

@keyframes titleShow {
  0% {
    margin-top: 10px;
    opacity: 0;
  }
  100% {
    margin-top: 25vh;
    opacity: 1;
  }
}

@keyframes diffShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes descShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cardIn {
  0% {
    opacity: 0;
    top: -50vh;
  }
  100% {
    opacity: 1;
    top: 0vh;
  }
}